<template>
  <a-layout class='index animated fadeIn'>
    <a-card>
         <SmallSquare content="用于PDA机码关联的判断" class="m-b-10"/>
      <a-form ref='form' :model='search' :style="{'marginBottom':'20px'}" :initialValues="search" :onFinish='pageSearchChange' layout='inline'>
        <a-form-item label="关键词" name='keywords'>
          <a-input v-model:value="search.keywords" style="width:250px" placeholder="请输入品牌商编号/账号"></a-input>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" htmlType='submit' >搜索</a-button>
        </a-form-item>
      </a-form>
      <a-table :pagination='false' :loading='listLoading' bordered rowKey='id' :columns='columns' :data-source='data' class="tableFixBox" :scroll="{ x: 'max-content'}">
        <template v-slot:virtual="{ record }">
            <a-switch v-model:checked="record.is_open_virtual" :checkedValue="1" :unCheckedValue="0"  checked-children="启用" un-checked-children="禁用" v-authAction:setPadVirtual="()=>switchChange(record)"></a-switch>
        </template>
        <template v-slot:action="{ record }">
            <a v-has:codePrefixDetail="()=>codePrefixDetail(record)" href="javascript:;" class="m-r-20">详情</a>
            <a class="m-r-20" v-has:codePrefixEdit="()=> {$router.push('/codeManage/codePrefixEdit?brand_id='+record.brand_no+'&company='+record.company)}" href="javascript:;">编辑</a>
            <a v-has:outCodeSetting="()=>{$router.push('/codeManage/outCodeSetting?brand_id='+record.brand_no+'&company='+record.company)}" href="javascript:;" >外部码配置</a>

        </template>
      </a-table>
      <a-pagination :style="{marginTop:'20px',textAlign:'right'}" show-size-changer show-quick-jumper :current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange" @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
    </a-card>
  </a-layout>
    <a-modal :visible="codeVisible" :footer="null" @cancel="codeVisible=false" :width="1200">
       <codePrefixDetail :brandData="brandData" />
    </a-modal>
</template>
<script>
// import { DownOutlined,QuestionCircleFilled,PlusOutlined } from "@ant-design/icons-vue";
import { ref, onMounted, inject, reactive, toRefs, h } from "vue";
import { message, Modal } from "ant-design-vue";
import { $iscode } from "@/utils/app";
import codePrefixDetail from './codePrefixDetail'
import {set_pad_virtual} from '@/api/codeManage'
import {
  getBrandtList,

} from "@/api/brand";
// 初始化默认筛选项数值
let defSearch = {
  keywords: "",
  status: "",
  page: 1,
  limit: 10,
};
export default {
  components:{codePrefixDetail},
  setup(props) {
    // // 初始化 分页信息和筛选项信息
    let search = ref({ ...defSearch });
    // 列表数据和列头配置
    let data = ref([]);
    const state = reactive({
      brandItem: {},
      total: 1,
      confirmLoading: false,
    });
    let columns = [
      {
        title: "品牌商编号",
        dataIndex: "brand_no",
        key: "brand_no",
      },
      {
        title: "公司名称",
        dataIndex: "company",
        key: "company",
      },
      {
        title: "状态",
        dataIndex: "account",
        key: "account",
      },
        {
        title: "使用垛标操作",
        key: "action",
        slots: { customRender: "virtual" },
      },
      {
        title: "操作",
        key: "action",
        width:220,
        fixed:"right",
        slots: { customRender: "action" },
      },
    ];
    const $router = inject("$router");
    const $Modal = inject("$Modal");
    // 页面筛选项搜索
    const pageSearchChange = () => {
      search.value = { ...search.value, page: 1 };
      initData(search.value);
    };
    // 分页当前页切换
    const pageCurrentChange = (page, pageSize) => {
      search.value.page = page;
      initData(search.value);
    };
    // 分页当前页显示多少条切换
    const pageSizeChange = (current, size) => {
      search.value.page = 1;
      search.value.limit = size;
      initData(search.value);
    };


    const initData = async (values) => {
      state.listLoading = true;
      data.value = [];
      try {
        let res = await getBrandtList(values).then(res=>res.data);
        state.listLoading = false;
        if ($iscode(res)) {
          data.value = res.data.data;
          state.total = res.data.total;
        } else {
          message.error(res.msg);
        }
      } catch (e) {
        state.listLoading = false;
      }
    };




    onMounted(() => {
      initData(search.value);
      
    });

    // 编辑 二维码前缀
   var  userPrefixHooks=function(){
        const codeVisible=ref(false)//弹窗
        const brandData=ref({})//品牌信息
        const codeForm=ref({})//表单对象
        const codePrefixDetail=(data)=>{
            brandData.value=data// 赋值
            codeVisible.value=true//打开弹窗
        }
        const codePrefixEditConfirm=()=>{
            // 调接口
            // 清空数据
            // 关闭弹窗
        }
        return{
            codeVisible,
            brandData,
            codeForm,
            codePrefixDetail,
            codePrefixEditConfirm
        }
    }
      const switchChange=async (e)=>{
        let res = await set_pad_virtual(e.brand_no,{is_open_virtual:e.is_open_virtual}).then(res => res.data);
       $iscode(res,true)
      initData(search.value);

    }

    return {
      ...toRefs(state),
      search,
      data,
      columns,
      pageSearchChange,
      pageCurrentChange,
      pageSizeChange,
      ...userPrefixHooks(),
      switchChange
    };
  },
};
</script>
