<template>
  <a-tabs>
    <a-tab-pane key="1" tab="二维码位数">
      <a-descriptions>
        <a-descriptions-item label="大标位数" :span="1">{{formState.big_code.join(',')}}</a-descriptions-item>
      </a-descriptions>
      <a-descriptions>
        <a-descriptions-item label="中标位数" :span="1">{{formState.middle_code.join(',')}}</a-descriptions-item>
      </a-descriptions>
      <a-descriptions>
        <a-descriptions-item label="小标位数" :span="1">{{formState.small_code.join(',')}}</a-descriptions-item>
      </a-descriptions>

    </a-tab-pane>
    <a-tab-pane key="2" tab="大标前缀">
      <a-descriptions v-for="v in prefix.big_code" :key="v">
        <a-descriptions-item label="前缀" :span="1">{{v}}</a-descriptions-item>
      </a-descriptions>
    </a-tab-pane>
    <a-tab-pane key="3" tab="中标前缀">
      <a-descriptions v-for="v in prefix.middle_code" :key="v">
        <a-descriptions-item label="前缀" :span="1">{{v}}</a-descriptions-item>
      </a-descriptions>
    </a-tab-pane>
    <a-tab-pane key="4" tab="小标前缀">
      <a-descriptions v-for="v in prefix.small_code" :key="v">
        <a-descriptions-item label="前缀" :span="1">{{v}}</a-descriptions-item>
      </a-descriptions>
    </a-tab-pane>
    <a-tab-pane key="5" tab="防伪码前缀">
      <a-descriptions v-for="v in prefix.security_code" :key="v">
        <a-descriptions-item label="前缀" :span="1">{{v}}</a-descriptions-item>
      </a-descriptions>
    </a-tab-pane>
  </a-tabs>
</template>


<script>
import { code_rule_prefix, prefixDetail } from "@/api/codeManage";
import { inject, onMounted, ref } from "@vue/runtime-core";
export default {
  name: "codePrefixDetail",
  props: {
    brandData: Object,
  },
  setup(props) {
    const route = inject("$route");
    const router = inject("$router");
    var formState = ref({
      big_code: [],
      middle_code: [],
      small_code: [],
    });
    var prefix = ref({
      big_code: [],
      middle_code: [],
      small_code: [],
      security_code: [],
    });
    const initData = async () => {
      console.log(props);
      // 获取位数
      let result = await code_rule_prefix(props.brandData.brand_no)
        .then((res) => res.data)
        .catch((error) => error);
      formState.value = result.data;
      // 获取前缀

      let res = await prefixDetail(props.brandData.brand_no)
        .then((res) => res.data)
        .catch((error) => error);

      prefix.value = res.data;
    };
    onMounted(() => {
      initData();
    });

    return {
      formState,
      prefix,
    };
  },
};
</script>